import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HolidaysModal from "../../components/holidays-modal";

import Icon from "../../components/custom-widgets/icon";
import Button from "../../components/custom-widgets/button";
import ChatSection from "../../components/contact-us/chat-section";

const ContactUsSpanish = () => {
  const title = "Contáctanos";
  const description =
    "¿Necesita contactar o llamar a WaFd Bank? Si necesita ayuda con su banca en línea, informe una tarjeta de crédito perdida o robada, comuníquese con WaFd Bank para obtener ayuda.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/contactanos"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Contáctanos"
    }
  ];

  const chatSectionData = {
    title: "Charlemos",
    description:
      "Nuestro Chatbot Walt está siempre disponible para contestar tus preguntas sobre la banca, ayudarle a encontrar su sucursal más cercana o encontrar a alguien para hablar con usted.",
    chatWaltButtonText: "Charla con Walt",
    chatWaltButtonId: "open-chat-walt-btn",
    customerServiceButtons: [
      {
        className: "mb-3",
        btnProps: {
          id: "contact-branch-cs-btn",
          text: "Contactar una Sucursal",
          icon: "../../images/icons/contact-branch.svg",
          iconHover: "../../images/icons/contact-branch-hover.svg"
        }
      },
      {
        className: "mb-3",
        btnProps: {
          id: "call-support-cs-btn",
          text: "Llamar a Soporte (24/7)",
          subtext: "800-324-9375",
          icon: "../../images/icons/call-support.svg",
          iconHover: "../../images/icons/call-support-hover.svg"
        }
      },
      {
        btnProps: {
          id: "email-support-cs-btn",
          text: "Enviar Correo a Soporte",
          subtext: "info@wafd.com",
          icon: "../../images/icons/email-support.svg",
          iconHover: "../../images/icons/email-support-hover.svg"
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container py-0">
        <h1>WaFd Bank Servicio al Cliente</h1>
      </section>
      <ChatSection chatSectionData={chatSectionData}>
        <div className="row">
          <div className="col-12 col-md-6 order-2 order-md-1 mb-3">
            <h4>Necesita ayuda con su tarjeta de debido WaFd?</h4>
            <div className="mb-3">
              <a id="c3-help-tel-1" className="text-decoration-none" href="tel:800-324-9375">
                <Icon class="mr-2" lib="fal" name="phone-alt" />
                Llame 800-324-9375
              </a>{" "}
              si perdió su tarjeta de débito
            </div>
            <div>
              <a id="pin-help-tel" className="text-decoration-none" href="tel:866-661-8550">
                <Icon class="mr-2" lib="fal" name="phone-alt" />
                Llame 866-661-8550
              </a>{" "}
              si olvido su PIN
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2 mb-3">
            <div className="mb-3">
              Para llamadas internacionales, por favor, marque{" "}
              <a id="international-help-tel" className="text-decoration-none" href="tel:1-206-624-7930">
                1-206-624-7930
              </a>
            </div>
            <div className="font-weight-bold text-green-60">
              El servicio al cliente de Wafd Bank está disponible las 24 horas al día los 7 días de la semana.
            </div>
          </div>
        </div>
      </ChatSection>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <StaticImage
                className="mb-4"
                src="../../images/wafd-voice-activated-banking-700.jpg"
                alt="Happy mature woman talking on the phone and smiling"
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-lg-6">
              <h2>Banca activada por voz</h2>
              <p className="text-green-60 font-weight-semi-bold">Registrarse es fácil:</p>
              <ol className="mb-3">
                <li>Si no lo ha hecho, regístrese como usuario en línea o a la banca móvil.</li>
                <li>
                  Después, llame al{" "}
                  <a id="c3-help-tel-2" href="tel:800-324-9375">
                    800-324-9375
                  </a>{" "}
                  para establecer su perfil en la banca activada por voz - se le guiará para grabar su voz, de esta
                  manera el servicio podrá identificarlo en el futuro.
                </li>
                <li>
                  En cualquier momento que llame, el servicio le pedirá identificarse usando su voz. Una vez
                  autenticado, el servicio le dirá "Hola, como puedo ayudarlo hoy?", para después ofrecerle opciones
                  incluyendo proporcionar su saldo, transferirlo, o llamar a un agente.
                </li>
              </ol>
              <Button
                id="learn-voice-activated-banking-btn"
                text="Aprenda Sobre la Banca Activada por Voz"
                showIcon={false}
                type="link"
                url="/personal-banking/voice-recognition-technology"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success my-3">
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <img
                src="/images/icons/home-owner-assistance-icon-128.svg"
                alt="House Chimney Icon"
                style={{ width: "102px" }}
              />
            </div>
            <div className="col">
              <h2 className="text-white">¿Es usted propietario de vivienda y está atravesando por dificultades?</h2>
              <Link
                id="homeowner-assistance-link"
                className="text-white hybrid-link h5 font-weight-bold text-decoration-none"
                to="/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios"
              >
                Aprenda Sobre la Asistencia a Propietarios
                <Icon class="ml-1" lib="fas" name="arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <h3>Nuestras sucursales están cerradas los siguientes días:</h3>
        <HolidaysModal />
      </section>
    </SecondaryLanding>
  );
};

export default ContactUsSpanish;
